<template>
  <div class="page-box">
    <div ref='table1' class="table1-data">
      <vxe-table border ref='xTable1' header-align="center" align="center" :height="tabHeight" :data="list">
        <vxe-column type="seq" width="60"></vxe-column>
        <vxe-column field="name" title="名称"></vxe-column>
        <vxe-column field="appKey" title="应用KEY"></vxe-column>
        <vxe-column field="appSecret" title="应用密钥"></vxe-column>
        <vxe-column width="200" fixed="right">
          <template slot="header">
            <Button type="primary" @click="SaveAccountInfo(true)">新增</Button>
            <Button type="success" @click="getList()" class="ml10">刷新</Button>
          </template>
          <template #default="{ row }">
            <Button type="primary" @click="SaveAccountInfo(false,row)" class="mr10">编辑</Button>
            <Button type="error" @click="DeleteAccountInfo(row)">删除</Button>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <ModalAccountEdit v-model="showModalAccountEdit" :item='item' @saved='getList'></ModalAccountEdit>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import ModalAccountEdit from './ModalAccountEdit'
export default {
  name: 'outputGroup',
  components: {
    ModalAccountEdit,
  },
  props: {

  },
  data() {
    return {
      showModalAccountEdit: false,
      item: {
        isAdd: false,
        data: {}
      },
      list: [],
      tabHeight: 500,
    }
  },
  computed: {
    ...mapState('group', ['groupTreeSelectedNode']),
  },
  watch: {
    'groupTreeSelectedNode.id'() {
      this.getList();
    },
    windowOnResize() {
      this.setTableHeight();
    },
  },
  mounted: function () {
    this.getList();
    setTimeout(this.setTableHeight, 200);
  },
  methods: {
    setTableHeight: function () {
      this.tabHeight = this.$refs.table1.clientHeight - 20;
    },
    getList() {
      this.$axios.post(`api/video/GetAccountList`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'list', res.data);
        }
      });
    },
    DeleteAccountInfo(el) {
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除[ ${el.name}] 吗?`,
        onOk: async () => {
          this.$axios.post(`api/video/DeleteAccountInfo`, { id: el.id }).then(res => {
            if (res.code == 0) {
              this.$Message.info('删除成功');
              this.getList();
            }
          });
        }
      });
    },
    SaveAccountInfo(isAdd, el) {
      this.item.isAdd = isAdd
      this.item.data = {}
      if (!isAdd) {
        this.item.data = el
      }
      this.showModalAccountEdit = true;
    },
  }
}
</script>
<style scoped>
.page-box {
  background-color: #fff;
  width: calc(100%);
  height: calc(100%);
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-content: stretch;
}
.list-query {
  height: 40px;
  flex: none;
  background-color: white;
  margin: 3px 1px;
  display: flex;
  align-items: center;
  padding: 10px;
  white-space: nowrap;
}
.table1-data {
  height: 100px;
  flex: auto;
}
</style>
