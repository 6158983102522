<template>
  <Modal v-model="showModal" :mask-closable="false" width="800px">
    <div slot="header">视频设备</div>
    <div>
      <Form ref="form" :model="form" :label-width="220">
        <FormItem prop="name" label="名称">
          <Input v-model="form.name" style="width: 200px"></Input>
        </FormItem>
        <FormItem prop="poleId" label="所在灯杆">
          <Select placeholder="所在灯杆" v-model="form.poleId" style="width: 200px;">
            <Option v-for="(s, idx) in poleData" :key="idx" :value="s.id">{{s.name}}</Option>
          </Select>
        </FormItem>
        <FormItem prop="accountId" label="关联账户">
          <Select placeholder="关联账户" v-model="form.accountId" style="width: 200px;">
            <Option v-for="(s, idx) in accountList" :key="idx" :value="s.id">{{s.name}}</Option>
          </Select>
        </FormItem>
        <FormItem prop="code" label="序列号">
          <Input v-model="form.code" style="width: 200px"></Input>
        </FormItem>
        <FormItem prop="valid" label="验证码">
          <Input v-model="form.valid" style="width: 200px"></Input>
        </FormItem>
        <FormItem prop="buildDate" label="安装时间">
          <DatePicker type="date" v-model="form.buildDate" placeholder="安装时间" style="width: 200px;margin-right: 10px;" />
        </FormItem>

      </Form>
    </div>
    <div slot="footer">
      <Button type="info" @click="showModal=false">取消</Button>
      <Button type="primary" @click="ok">确定</Button>
    </div>
  </Modal>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'ModalSwitchEdit',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showModal: false,
      poleData: [],
      accountList: [],
      form: {
        "id": "",
        "poleId": "", //所在灯杆
        "accountId": "", //接入账户
        "name": '', //名称
        "code": "", //序列号
        "valid": "", //验证码
        "buildDate": "", //安装时间
      }
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        this.dataAjax();
        if (this.item.isAdd) {
          this.form = {
            "id": "",
            "poleId": "", //所在灯杆
            "accountId": "", //接入账户
            "name": '', //名称
            "code": "", //序列号
            "valid": "", //验证码
            "buildDate": "", //安装时间
          }
        } else {
          this.$set(this, 'form', this.item.data);
        }
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },

  },
  computed: {
    ...mapGetters('auth', ['showLang']),
    ...mapState('group', ['groupTreeSelectedNode']),
    ...mapState('sys', ['myMenus']),
  },
  mounted: function () {
  },
  methods: {
    dataAjax() {
      let arr = {
        "groupId": this.groupTreeSelectedNode.id, //用户选择的左树分区节点id，为空表示根节点或选择站点
        "poleId": "", //用户选择的左树灯杆节点ID，
        "name": "", //站点名称
        "index": 1, //分页码
        "size": 2000000, //分页大小
      }
      this.$axios.post(`api/core/GetPoleList`, arr).then(res => {
        if (res.code == 0) {
          this.$set(this, 'poleData', res.data.list);
        }
      });
      this.$axios.post(`api/video/GetAccountList`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'accountList', res.data);
        }
      });
    },

    ok() {
      this.$axios.post(`api/video/SaveVideoDevice`, this.form).then(res => {
        if (res.code == 0) {
          this.$Message.info('设置成功');
          this.$emit("saved");
          this.showModal = false;
        }
      });
    },
  }
}
</script>
<style scoped>
</style>
